ACC.addressDoctorDataLayer = {
    _autoload: [
        'bindSelectUseAsIsAddressButton',
        'bindSelectSingleCTAAddressButton',
    ],
    addressDoctorFunnelText: 'address doctor',
    bindSelectUseAsIsAddressButton() {
        const listOfAddress = $('input[id="addressSize"]').val();
        if (listOfAddress > 1) {
            $('#use_suggested_address_button').on('click', ACC.addressDoctorDataLayer.sendADAnalyticsDataForMultiple);
        } else {
        // eslint-disable-next-line max-len
            $('#use_suggested_address_button').on('click', ACC.addressDoctorDataLayer.sendAddressDoctorAnalyticsDataForUseAsIs);
            ACC.addressDoctorDataLayer.sendErrorADAnalytics();
        }
    },
    bindSelectSingleCTAAddressButton() {
        // eslint-disable-next-line max-len
        $('.suggested_address_single_cta').on('click', ACC.addressDoctorDataLayer.sendSingleAddressDoctorAnalyticsData);
    },
    sendSingleAddressDoctorAnalyticsData() {
        // eslint-disable-next-line max-len
        ACC.addressDoctorDataLayer.sendAddressDoctorAnalyticsDataForCompleteProcess(ACC.addressDoctorDataLayer.getSelectedAddressForUserSelection());
    },

    sendADAnalyticsDataForMultiple() {
        // eslint-disable-next-line max-len
        ACC.addressDoctorDataLayer.sendAddressDoctorAnalyticsDataForCompleteProcess(ACC.addressDoctorDataLayer.getSelectedAddressForUserSelection());
    },

    sendAddressDoctorAnalyticsDataForUseAsIs() {
        ACC.addressDoctorDataLayer.sendAddressDoctorAnalyticsDataForCompleteProcess('0');
    },

    sendAddressDoctorAnalyticsDataForStartProcess() {
        const dataLayer = window.dataLayer || [];
        ACC.addressDoctorDataLayer.pushADProcessStartAnalyticsData(dataLayer);
    },

    sendAddressDoctorAnalyticsDataForCompleteProcess(selectedAddressOption) {
        const dataLayer = window.dataLayer || [];
        // eslint-disable-next-line prefer-template
        const selectedAddress = $('.js-selected-address-suggestion');
        const id = selectedAddress.attr('data-suggested-address-id');
        const type = selectedAddress.attr('data-suggested-address-type');
        const analyticsBody = {
            funnel: ACC.addressDoctorDataLayer.addressDoctorFunnelText,
            stageName: ACC.addressDoctorDataLayer.getStageNameForSuggestedAddress(),
            selectedAddress: selectedAddressOption,
            addressId: id,
            addressType: type,
        };
        ACC.addressDoctorDataLayer.pushADProcessCompleteAnalyticsData(dataLayer, analyticsBody);
        ACC.addressDoctorDataLayer.pushADPageUserINfoAnalyticsData(dataLayer, analyticsBody);
    },

    pushADProcessStartAnalyticsData(dataLayer) {
        dataLayer.push({
            event: 'processStart',
            processName: ACC.addressDoctor.addressDoctorFunnelText,
        });
    },
    pushADProcessCompleteAnalyticsData(dataLayer, analyticsBody) {
        dataLayer.push({
            event: 'processComplete',
            processName: ACC.addressDoctor.addressDoctorFunnelText,
            data: analyticsBody,
        });
    },
    getStageNameForSuggestedAddress() {
        const addressSize = $('input[id="addressSize"]').val();
        let result = '';
        if (addressSize === '0') {
            result = 'not verified address';
        } else if (addressSize === '1') {
            result = 'single address';
        } else {
            result = 'multiple addresses';
        }
        return result;
    },

    getSelectedAddressForUserSelection() {
        let index;
        $('input:radio').each(function () {
            if ($(this).is(':checked')) {
                index = $(this).attr('id').split('-').pop();
                index += 1;
            }
        });
        return index;
    },

    pushADPageUserINfoAnalyticsData(dataLayer) {
        const { pageInfo } = dataLayer[0];
        const { userInfo } = dataLayer[0];
        dataLayer.push({
            event: 'scopeData',
            dataType: 'pageInfo',
            pageInfo: {
                breadcrumb: pageInfo.breadcrumb,
                dateCreated: pageInfo.dateCreated,
                lastUpdated: pageInfo.lastUpdated,
                pageTemplate: pageInfo.pageTemplate,
                pageCategory: pageInfo.pageCategory,
            },
            userInfo: {
                visitorType: userInfo.visitorType,
                context: userInfo.context,
                iesSessionId: userInfo.iesSessionID,
                iesUserId: userInfo.iesID,
            },
        });
    },
    sendErrorADAnalytics() {
        const dataLayer = window.dataLayer || [];
        dataLayer.push({
            event: 'error_addressDoctor',
            error_message: ACC.config.addressDoctorEmptyListMsg,
        });
    },
};
